<template>
	<section class="portfolio-details col" align-x="left" :key="id">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item to="/manager/portfolio">系列作品</el-breadcrumb-item>
			<el-breadcrumb-item>{{_portfolio.title}}</el-breadcrumb-item>
		</el-breadcrumb>
		<br>
		<h1>{{_portfolio.title}}</h1>
		<hr>
		<br>
		<p>選擇類別（或填入新類別）</p>
		<el-card>
			<el-form :model="$data" ref="ruleForm">
				<el-form-item prop="type" label="類別" :rules="{required:true, message:'必填', trigger:'blur'}">
					<el-autocomplete v-model="type" :fetch-suggestions="querySearch" placeholder="選擇類別（或填入新類別）" />
				</el-form-item>
				<el-form-item>
					<div class="row" align-x="right">
						<el-button @click="handleSave({ type })" type="success">儲存</el-button>
						<el-button @click="handleCancel('type')" type="info">取消</el-button>
					</div>
				</el-form-item>
			</el-form>
		</el-card>
		<template v-if="!isNew">
			<br>
			<p>上傳縮圖</p>
			<el-card>
				<div class="row" gutter="10">
					<div>
						<upload-thumb v-model="thumb" />
					</div>
					<div class="row" align-self="bottom">
						<el-button @click="handleSave({ thumb })" type="success">儲存</el-button>
						<el-button @click="handleCancel('thumb')" type="info">取消</el-button>
					</div>
				</div>
			</el-card>
			<br>
			<br>
			<p>上傳 Banner</p>
			<el-card>
				<div class="row" gutter="10">
					<div>
						<upload v-model="banners" multiple />
					</div>
					<div class="row" align-self="bottom">
						<el-button @click="handleSave({ banners })" type="success">儲存</el-button>
						<el-button @click="handleCancel('banners')" type="info">取消</el-button>
					</div>
				</div>
			</el-card>
			<p>上傳 Banner 手機版</p>
			<el-card>
				<div class="row" gutter="10">
					<div>
						<upload v-model="banners_mobile" multiple />
					</div>
					<div class="row" align-self="bottom">
						<el-button @click="handleSave({ banners_mobile })" type="success">儲存</el-button>
						<el-button @click="handleCancel('banners_mobile')" type="info">取消</el-button>
					</div>
				</div>
			</el-card>
		</template>
		<br>
		<br>
		<el-card>
			<el-form :model="$data">
				<el-form-item prop="title" label="標題">
					<el-input v-model="title" />
				</el-form-item>
				<el-form-item prop="sub_title" label="小標題">
					<el-input v-model="sub_title" />
				</el-form-item>
				<el-form-item prop="youtube" label="Youtube 網址">
					<el-input v-model="youtube" />
				</el-form-item>
				<el-form-item v-if="youtubeId" label="預覽">
					<div class="youtube">
						<iframe :src="`https://www.youtube.com/embed/${youtubeId}`" frameborder="0" allowfullscreen />
					</div>
				</el-form-item>
				<el-form-item>
					<div class="row" align-x="right">
						<el-button @click="handleSave({ title, sub_title, youtube })" type="success">儲存</el-button>
						<el-button @click="handleCancel(['title', 'sub_title', 'youtube'])" type="info">取消</el-button>
					</div>
				</el-form-item>
			</el-form>
		</el-card>
		<br>
		<br>
		<p>編輯內容</p>
		<el-card>
			<tinymce v-model="details" />
			<div class="center">
				<el-button @click="handleSave({ details })" type="success">儲存</el-button>
				<el-button @click="handleCancel('details')" type="info">取消</el-button>
			</div>
		</el-card>
		<template v-if="id && id != 'new'">
			<br>
			<br>
			<el-button @click="handleRemove()" type="danger" align-self="center">刪除此項作品</el-button>
		</template>
		<br>
	</section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import querystring from "querystring";
export default {
	components: {
		UploadThumb: () => import("@/components/upload-thumb"),
		Upload: () => import("@/components/upload"),
		Tinymce: () => import("@/components/tinymce"),
	},
	props: ["id"],
	data() {
		return {
			type: "",
			thumb: "",
			banners: [],
			banners_mobile: [],
			title: "",
			sub_title: "",
			youtube: "",
			details: "",
		}
	},
	computed: {
		...mapState("portfolio", ["_portfolioList", "_portfolio"]),
		isNew() {
			return this.id == "new";
		},
		youtubeId() {
			const { youtube } = this._portfolio;
			return this.youtube && querystring.parse(new URL(this.youtube).searchParams.toString())["v"] || ""
		},
		typeList() {
			return this._portfolioList
				.reduce((a, { type }) => type && a.includes(type) ? a : a.concat(type), new Array);
		},
	},
	methods: {
		...mapActions("portfolio", ["_getData", "_getList", "_updateData", "_createData", "_removeData"]),
		async handleSave(data) {
			if (this.type) {
				if (this.isNew) {
					const { id, message } = await this._createData(data);
					this.$message.success({ message });
					this.$router.push(`/manager/portfolio/${id}`);
				}
				else this.$message.success(await this._updateData({
					id: this.id,
					data
				}));
				if (!this.typeList.includes(this.type)) await this._getList();
			} else this.$message.error("請填入類別")
		},
		handleCancel(key) {
			if (key.forEach) {
				key.forEach(k => {
					this.$set(this.$data, k, this._.cloneDeep(this._portfolio[k]));
				});
			} else {
				this.$set(this.$data, key, this._.cloneDeep(this._portfolio[key]));
			}
		},
		querySearch(string, callback) {
			const results = string
				? this.typeList
					.filter(item => item.includes(string))
				: this.typeList;
			// 調用 callback 返回建議列表的數據
			callback(results.map(value => ({ value })));
		},
		handleRemove() {
			this.$confirm(`確認要刪除「${this._portfolio.title || "尚未命名"}」？`, { type: "warning" })
				.then(async () => {
					this.$message.success(await this._removeData(this.id));
					this.$router.push("/manager/portfolio");
				});
		}
	},
	async created() {
		if (!this._portfolioList.length) await this._getList();
		if (!this.isNew) {
			await this._getData(this.id);
			this._.assign(this.$data, this._.cloneDeep(this._portfolio));
		}
	}
}
</script>